import { Link } from "@StarberryUtils";
import React from "react"
import { Row, Col, Container, Dropdown } from "react-bootstrap"
import BannerImg376 from "../../../images/static-banner376-img.jpg"
import BannerImg768 from "../../../images/static-banner768-img.jpg"
import BannerImg from "../../../images/static-banner-img.jpg"
import "../../MgServices/Breadcrumb/Breadcrumb.scss"
import "./StaticBanner.scss"
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/news-breadcrumb"
import ShareImg from "../../../images/share-white.png";

import Video from "../../Play/head-cta-video";
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby";
import logoImgLove from "../../../images/nav-logo-love-new.svg";


import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";


import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";


const StaticBannerAreaGuide = (props) => {
  
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const [Shareicons,setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
  }

  const closeShareicons = () => {
    setShareicons(false);
  }

  
  const location = useLocation();
    
  const shareUrl = location.href


  const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

  const currentURL = typeof window !== 'undefined' ? window.location.href : ''
  const splitCurrentURL = currentURL.split("/")
  const currentPage = splitCurrentURL[splitCurrentURL.length - 2]
  const parentPage = splitCurrentURL[splitCurrentURL.length - 3]
 
  const data = useStaticQuery(graphql`
  query GetSpecificCategory {
    allStrapiLocalLifeCategories {
      edges {
        node {
          strapiId
          URL
          local_life_things_to_dos {
            Category 
          }
        }
      }
    }

    allStrapiAreaGuides {
      edges {
        node {
          Title
          URL
          strapiId
          Local_life_things_to_dos {
            Category
          }
        }
      }
    }
  }
  `)
  
  const specificCategory = data.allStrapiLocalLifeCategories.edges.filter(
    (edge) => edge.node.URL === parentPage
  )

  let areas_list = [];

  if(specificCategory.length > 0) {
    const specificCategoryId = specificCategory[0].node.strapiId

    // get the allStrapiAreaGuides where the Category in local_life_things_to_dos array  is equual to specificCategoryId

    if(specificCategoryId != null) {
      const specificCategoryAreaGuides = data.allStrapiAreaGuides.edges.filter(
        (edge) =>
          edge.node.Local_life_things_to_dos.filter(
            (local_life_things_to_do) =>
              local_life_things_to_do.Category === specificCategoryId
          ).length > 0
      )

      if(specificCategoryAreaGuides && specificCategoryAreaGuides.length > 0) {
        areas_list = specificCategoryAreaGuides.map((areaGuide) => areaGuide.node).filter((areaGuide) => areaGuide.URL !== currentPage)
      }
    }
  }

  return (
    <div className="static-banner">
      <div className="banner-item">
        {props.prop == "no-image" ? (
          <picture>
            <source
              media="(min-width:992px)"
              srcset={props.Banner_Image ? props.Banner_Image : BannerImg}
            />
            <source
              media="(min-width:768px)"
              srcset={props.Banner_Image ? props.Banner_Image : BannerImg768}
            />
            <img
              loading="lazy"
              className="banner-img"
              src={props.Banner_Image ? props.Banner_Image : BannerImg376}
              alt={`${props.page} ${
                props.Title ? props.Title.replace(/(<([^>]+)>)/gi, "") : ""
              }`}
            />
          </picture>
        ) : (
          <picture>
            <source
              media="(min-width:992px)"
              srcset={
                props.Banner_Image
                  ? props.Banner_Image.childImageSharp.gatsbyImageData.images
                      .fallback.src
                  : BannerImg
              }
            />
            <source
              media="(min-width:768px)"
              srcset={
                props.Banner_Image
                  ? props.Banner_Image.childImageSharp.gatsbyImageData.images
                      .fallback.src
                  : BannerImg768
              }
            />
            <img
              loading="lazy"
              className="banner-img"
              src={
                props.Banner_Image
                  ? props.Banner_Image.childImageSharp.gatsbyImageData.images
                      .fallback.src
                  : BannerImg376
              }
              alt={`Area Guide ${
                props.Title ? props.Title.replace(/(<([^>]+)>)/gi, "") : ""
              } - Martyn Gerrard`}
            />
          </picture>
        )}
        <div className="banner-content mgservice-head">
          <Container>
            <Row>
              <Col xs={12} className="exp-btns">
                {props.tag != "local-life" ? (
                  <Breadcrumbs
                    url="area-guides"
                    tag="contact"
                    Title={props.Title}
                  />
                ) : (
                  <Breadcrumbs
                    url={props.main_url == "main" ? "about" : "area-guides"}
                    category={props.category}
                    Title={props.Title.replace(/(<([^>]+)>)/gi, "")
                      .replace("&amp;", "&")
                      .replace(" &amp;", " &")
                      .replace("&nbsp;", "  ")}
                  />
                )}
                {props.tag != "local-life" ? (
                  <h1>{props.Title} Area Guide</h1>
                ) : (
                  ""
                )}
                {props.tag == "local-life" ? (
                  <ReactMarkdown source={props.Title} allowDangerousHtml />
                ) : (
                  ""
                )}
                <div className="btns-wrp d-md-flex align-items-center">
                  {props.video ? (
                    <Video
                      title={props.Title.replace(/(<([^>]+)>)/gi, "")
                        .replace("&amp;", "&")
                        .replace(" &amp;", " &")
                        .replace("&nbsp;", "  ")}
                      class="btn d-block d-md-inline-block cta-1"
                      label="Area Guide Video"
                      url={props.video}
                    />
                  ) : (
                    ""
                  )}
                  {props.Area_local_loving_life ? (
                    <Link to="local-loving-life" className="btn cta-1">
                      Loving Local Life
                    </Link>
                  ) : (
                    ""
                  )}

                  {props.all_cat ? (
                    <>
                      {props.all_cat.map((data, i) => {
                        return (
                          <Link
                            to={`../../../${data.URL}/${props.page_url}`}
                            className="btn"
                          >
                            {data.Name}
                          </Link>
                        )
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  {areas_list && areas_list.length > 0 ? (
                    <div className="area_drop_down">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          All Areas
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {areas_list.map((data, i) => {
                            return (
                              <Dropdown.Item>
                                <Link to={`../${data.URL}`}>{data.Title}</Link>
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {props.tag != "local-life" ? (
                  <div
                    className="share"
                    onMouseEnter={openShareicons}
                    onMouseLeave={closeShareicons}
                  >
                    <p>
                      <img loading="lazy" src={ShareImg} alt="" />
                      <span>Share with a friend</span>
                    </p>
                    {Shareicons && (
                      <div
                        onMouseEnter={openShareicons}
                        onMouseLeave={closeShareicons}
                        className="property-share-social-icons"
                      >
                        <FacebookShareButton
                          onClick={() => trackerShare("FacebookShareButton")}
                          url={shareurl}
                          className="my-share-button facebook-share"
                        >
                          <FacebookIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </FacebookShareButton>
                        <TwitterShareButton
                          onClick={() => trackerShare("TwitterShareButton")}
                          url={shareurl}
                          className="my-share-button twitter-share"
                        >
                          <TwitterIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          onClick={() => trackerShare("LinkedinShareButton")}
                          url={shareurl}
                          className="my-share-button linkedin-share"
                        >
                          <LinkedinIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </LinkedinShareButton>
                        <WhatsappShareButton
                          onClick={() => trackerShare("WhatsappShareButton")}
                          url={shareurl}
                          className="my-share-button whatsapp-share"
                        >
                          <WhatsappIcon
                            size={32}
                            round={false}
                            borderRadius={`10`}
                          />
                        </WhatsappShareButton>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <div className="local-loves-redirect">
              <Link to="/about/loving-life/" className="back-to text-uppercase">
                {" "}
                <span>&lt; Back to</span>
                <img className="back-to-logo" src={logoImgLove} alt="local loves logo"></img>
              </Link>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default StaticBannerAreaGuide
